import "@fancyapps/ui/src/Fancybox/Fancybox.js";
import './ie'
import './jquery'
import './forms'
import './tilt3d'
import './gradients'
import './menu'
import './scrollVideo'
import './blog'
import './parallaxImage'
import './bgDotPattern'
import './swiper'
import './countUp'
import './ellipsis'
import './animations'
import './imgFit'
import './collapseCards'
import './watchScroll'
import './typeAlternatives'
import './scrollHint'
import './threedee'
import './video'
import './collage'
import './serial'
import './downloads'
import './accordion'
import './countdown'

//////////////////////
///////////////////////////////////////////

//////////////////////////////////////////////
//////////////////////
/////////////////////////////
/////////////////////////////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////////////////////
//////
 
////////////////////
////////////////////
/////////////////
////////////////
//////////////////////
///////////////////////
//////////////////////////
////////////////
//////////////////////
///////////////////////
////////////////
//
///////////////////////////////////
//////////
